@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
:root
{
  --body-bg:#0a111f;
  --body-text-color:#ceced3;
  --orange: #f25b08;
}
body
{
  background-color: var(--body-bg) !important;
  margin:0;
  min-height: 100vh;
  color: var(--body-text-color) !important;
  overflow-x: hidden;
}
h1,h2,h3,h4,h5,h6{font-family: "Lexend", sans-serif;font-optical-sizing: auto;}
p{font-size:1.2rem;}
.about-section p{text-align: justify;}
.text-orange{color:var(--orange)}
.logo
{
  height: 50px;
   
}
.nav-item a
{
  color:#fff !important;
  font-family: "Lexend", sans-serif;font-optical-sizing: auto;
  font-size: 1.1rem;
}
.nav-item a:hover
{
  color:var(--orange) !important;
  transition: 0.2s;
}
.herobg
{
  background-image:url('../public/herobg2.png');
  min-height: 500px;
  background-size: cover;
  aspect-ratio: 2;
  border-top:2px solid var(--orange);
 
  position: relative;
}
.herobg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #bd430d, #6f430d);
  opacity: 0.6;
  z-index: 1;
}
.shape-5 {
  position: absolute;
  bottom: 0;
  z-index:2;
  width:100%;

}
.herobg .row {
  position: relative;
  z-index: 3; /* Ensure the content stays above the overlay */
}
.navbar-toggler-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.herobg h1{font-size:5rem;color:#fff;color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffffff;}
.herobg h5{font-size:2.2rem;color:#fff;}
.about-section
{
  background-image:url('../public/aboutbg.png');
  min-height: 500px;
  background-size: cover;
}
.orangeColor{color:var(--orange);}
.orangeDivider{border-top:3px solid var(--orange);margin:5px;width: 10%;margin-left:0;opacity:0.8}
.girlabout{position: absolute;top:-30%;height:800px;transform: rotate(315deg);}


.service-card
{
  border:2px solid #2d374d;
  border-radius: 30px;
  padding: 10px 20px;
  transition: border-color 0.3s ease-in-out;
  animation: borderAnimation 2s linear infinite;
  background-color: #151e31;
}
.cardCount
{
  position: absolute;
    color: var(--orange);
    font-weight: bold;
    font-size: 6rem;
    opacity: 0.1;
    z-index: 1;
    /* top: 0%; */
    margin-top: -30px;
}
.service-card h3{z-index:2 !important;padding-top:40px;position: relative;transition: color 0.3s ease-in-out;}
.service-card:hover {
  border-color: var(--orange); /* Change border color to orange */
}

.service-card:hover h3 {
  color: var(--orange); /* Change h3 color to orange */
}
.gallerySlideImg
{
  border-radius: 30px;
  max-height:250px;
  width:100%;
  margin:10px;
}
input, textarea 
    {
      background-color: var(--body-bg) !important;
      border-color: #5b5c66 !important;
      color: white !important;
      padding: 0.575rem 0.75rem !important;
    }

    input::placeholder, textarea::placeholder 
    {
      color: var(--body-text-color) !important;
    }
    input:focus, textarea:focus {
      outline: none;
      border-color: var(--orange) !important; /* Orange border on focus */
      box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.2) !important; /* Orange shadow */
    }

    .contact-btn
    {
      background-color: var(--orange) !important;
      
      padding:6px 30px !important;
      font-size:1.1rem !important;
      border:2px solid var(--orange) !important;
      transition: 0.2s ease-in-out;
      box-shadow:0px 0px 15px 2px rgba(0, 0, 0, 0.3);
    }
    .contact-btn:hover
    {
      color:var(--orange) !important;
      background-color: var(--body-bg) !important;
      transition: 0.2s ease-in-out;
    }
    .footer-note-div
    {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .footer-link{text-decoration: none; color: var(--body-text-color);margin-left:10px}
    .footer-note{font-family: "Caveat", cursive;color:#484848;font-size:5rem;}
    .clientImg{height:80px;filter: grayscale(1) invert(1);
      opacity: 0.3;}
      .clientImg:hover{height:80px;filter: grayscale(0) invert(0);
        opacity: 1; background-color: #eee; transition: 0.3s;}
    @media only screen and (max-width: 500px)
    {
      .herobg h1{font-size:2.7rem;}
      .herobg h5{font-size:1.7rem;color:#fff;}
      .herobg .vh-100{height:100vh !important}
      .footer-note{font-family: "Caveat", cursive;color:#484848;font-size:3rem;}
      .mt-sms-3{margin-top: 1rem !important;}
      .orangeDivider{border-top:3px solid var(--orange);margin:5px;width: 20%;margin-left:0;opacity:0.8}
      .footer-note-div
    {
      display: block;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .clientImg{height:80px;filter: grayscale(0) invert(0);background-color: #eee;opacity: 0.8;}
    }